<template>
  <section>
    <!--工具条-->
    <toolbar :buttonList="buttonList"
             @callFunction="callFunction"
             :buttonListmsg="buttonListmsg"></toolbar>
    <!--列表-->
    <el-table :data="users"
              highlight-current-row
              row-key="Id"
              lazy
              :load="load"
              :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
              :fit="true"
              @current-change="selectCurrentRow"
              :row-style="{height:'50px'}"
              :cell-style="{padding:'0px'}"
              :header-cell-style="{background:'#dce4f1',color:'#000000'}"
              @row-dblclick="checkInfo"
              style="width: 100%">
      <!-- <el-table-column type="index"></el-table-column> -->
      <el-table-column type="index" width="6">
        <template scope="scope">
        </template>
      </el-table-column>
      <el-table-column label="工作项目"
                       prop="WorkItem"
                       min-width="260px"
                       >
        <template slot-scope="scope">
            <span :style="{'cursor':'default'}">{{scope.row.Number}}、{{scope.row.WorkItem}}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="具体描述及要求" prop="WorkDescription"></el-table-column> -->
      <!-- <el-table-column label="计划完成时间" prop="PlanComplateTime"  width="150" align="center" :formatter="formatPlanComplateTime" sortable></el-table-column> -->
      <el-table-column label="责任人"
                       prop="PersonOfDuty"
                       min-width="90px"></el-table-column>
      <el-table-column label="进度"
                       prop="TaskProgressValue"
                       :formatter="formatProgress"
                       align="center"
                       min-width="60px"></el-table-column>
      <el-table-column label="创建时间"
                       prop="CreateTime"
                       min-width="100px"
                       :formatter="formatCreateTime">
        <template slot-scope="scope">
          <el-tooltip placement="left">
            <div slot="content">
              {{ formatTipCreateTime( scope.row) }}
            </div>
            <div>
              {{ formatCreateTime( scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="任务类型"
                       prop="CommonTaskClassifyText"
                       min-width="100px"
                       :formatter="formatCommonTaskClassifyText"></el-table-column>
      <el-table-column label="申请操作"
                       prop="BeforeTaskStatus"
                       min-width="105px">
        <template slot-scope="scope">
            <div style="cursor:default;">
              <!-- <el-tag v-if="scope.row.BeforeTaskStatus == 0"  type="danger">进行中</el-tag>-->
              <el-tag v-if="scope.row.BeforeTaskStatus == 1 || scope.row.ApprovalType == 1">已完成待审核</el-tag>
              <!-- <el-tag v-if="scope.row.BeforeTaskStatus == 2 || scope.row.ApprovalType == 1">已完成待审核</el-tag> -->
              <!-- <el-tag v-if="scope.row.BeforeTaskStatus == -1" type="danger">已取消</el-tag>
                                    <el-tag v-if="scope.row.BeforeTaskStatus == 3"  type="danger">退回</el-tag> -->
              <el-tag v-if="scope.row.BeforeTaskStatus == 4 || scope.row.ApprovalType == 2">执行前待审核</el-tag>
              <el-tag v-if="scope.row.BeforeTaskStatus == 5">取消任务</el-tag>
            </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="任务审核后状态" prop="AfterTaskStatus" align="center" sortable>
                        <template slot-scope = "scope">
                            <el-tooltip placement="top">
                                <div slot="content">
                                    已完成{{  scope.row.TaskProgressValue }}%
                                </div>
                                <div>
                                    <el-tag v-if="scope.row.AfterTaskStatus == 0"  type="danger">进行中</el-tag>
                                    <el-tag v-if="scope.row.AfterTaskStatus == 1"  type="danger">审批中</el-tag>
                                    <el-tag v-if="scope.row.AfterTaskStatus == 2"  type="success">已完成</el-tag>
                                    <el-tag v-if="scope.row.AfterTaskStatus == -1" type="danger">已取消</el-tag>
                                    <el-tag v-if="scope.row.AfterTaskStatus == 3"  type="danger">退回</el-tag>
                                    <el-tag v-if="scope.row.AfterTaskStatus == 6"  type="danger">新建任务退回</el-tag>  
                                </div>
                            </el-tooltip>
                        </template>
                </el-table-column>  -->
      <!-- <el-table-column label="当前任务状态" prop="Status" align="center" sortable>
                        <template slot-scope = "scope">
                            <el-tooltip placement="top">
                                <div slot="content">
                                    已完成{{  scope.row.TaskProgressValue }}%
                                </div>
                                <div>
                                    <el-tag v-if="scope.row.Status == 0"  type="danger">进行中</el-tag>
                                    <el-tag v-if="scope.row.Status == 1"  type="danger">审批中</el-tag>
                                    <el-tag v-if="scope.row.Status == 2"  type="success">已完成</el-tag>
                                    <el-tag v-if="scope.row.Status == -1" type="danger">已取消</el-tag>
                                    <el-tag v-if="scope.row.Status == 3"  type="danger">退回</el-tag>
                                    <el-tag v-if="scope.row.Status == 6"  type="danger">新建任务退回</el-tag>  
                                </div>
                            </el-tooltip>
                        </template>
                </el-table-column> -->
      <!-- <el-table-column label="任务来源" prop="Source"  width="100"  :formatter="formatSource" sortable align="center"></el-table-column> -->
      <el-table-column label="审批结果"
                       prop="ApprovalStatus"
                       min-width="100px">
        <template slot-scope="scope">
          <el-tag v-show="scope.row.PersonOfDuty!='' "
                  :type="scope.row.ApprovalStatus==1 ? 'success' : 'danger'"
                  disable-transitions
                  effect="dark">
            {{scope.row.ApprovalStatus==1 ? "审批通过" : "退回"}}
            <!-- {{scope.row.ApprovalStatus}} -->
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="审批人"
                       min-width="100px">
        <template slot-scope="scope">
          <div v-show="scope.row.PersonOfDuty!=''">{{approvalpeo}}</div>
        </template>
      </el-table-column>
      <el-table-column label="审批意见"
                       prop="ApprovalContent"
                       min-width="100px"></el-table-column>
      <el-table-column label="任务评分"
                       prop="Score"
                       min-width="75px">
        <template slot-scope="scope">
          <div v-if="scope.row.Score==0"></div>
          <div v-else-if="scope.row.Score==1">A</div>
          <div v-else-if="scope.row.Score==2">B</div>
          <div v-else-if="scope.row.Score==3">C</div>
          <div v-else-if="scope.row.Score==4">D</div>
        </template>
      </el-table-column>
      <el-table-column label="审批时间"
                       prop="ApprovalTime"
                       min-width="100px"
                       :formatter="formatApprovalTime">
        <template slot-scope="scope">
          <el-tooltip placement="left">
            <div slot="content">
              {{ formatTipApprovalTime( scope.row) }}
            </div>
            <div>
              {{ formatApprovalTime( scope.row) }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否需审核"  width="120"  prop="IsNeedApproval" sortable align="center">
                    <template slot-scope = "scope">
                    <el-tag :type = "scope.row.IsNeedApproval ? 'danger' : 'success'"  disable-transitions>
                        {{scope.row.IsNeedApproval ? "是" : "否"}}
                    </el-tag>
                    </template>
                </el-table-column> -->
      <!-- <el-table-column label="备注"  width="90"  prop="Remark"></el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" v-show="scope.row.PersonOfDuty!=''"
                      size="medium"
                      @click="checkInfo(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination align='center'
                   @current-change="handleCurrentChange"
                   :current-page="currentPage"
                   :page-size="pageSize"
                   layout="prev, pager, next, jumper"
                   :page-count="total"
                   :total="totaldata">
    </el-pagination>

    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>



  </section>
</template>
<script>
import util from '../../../util/date';
import { AddTaskProgressReview,QueryReviewByProgressId,QueryPageProcessedTasksByUserCode20210130, GetClassifyList, QueryTaskProgressByTaskId, QueryChildTasksByParentId, QueryTasksById } from '../../api/oa';
import taskinfo from "../../components/taskinfo.vue"
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
export default {
  components: { taskinfo, Toolbar },
  data () {
    return {
      currentActiveId: null,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 15, // 每页的数据条数
      page: 1,
      buttonList: [],
      users: [],
      tasks: [],
      content:'',
      mapReview:new Map(),
      approvalpeo: null,
      CommonTaskClassifyList: [],
      CommonTaskClassifyText: null,
      currentRow: null,
      buttonListmsg: 'taskmanage',
      checkVisible: false,
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      pValue1: null,   //进度条数值
      customColor: '#6f7ad3',   //进度条颜色
      filters: {
        name: ''
      },
      checkForm: {           //查看表单初始化
        Id: null,
        CommonTaskClassify: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null

      }
    }
  },
  methods: {
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    callFunction (item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    load (tree, treeNode, resolve) {
      var notaskType = tree.NoTask;
      let para = {
        taskId: tree.TaskId,
        notaskType: notaskType,
      };
      QueryChildTasksByParentId(para).then((res) => {
        var myarr = new Array();
        var number = 0;
        res.data.response.forEach(element => {
            element.Number = ++number;
            myarr.push(element)
        })
        resolve(myarr);
        // resolve(res.data.response)
      });
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatProgress: function (row, col) {
      if (row.PersonOfDuty != '') {
        return row.TaskProgressValue + "%";
      } else {
        return '';
      }
    },
    formatStatusTip: function (row, column) {
      if (row.BeforeTaskStatus == 1) {
        return "任务完成后需由你审核";
      } else if (row.BeforeTaskStatus == 4) {
        return "此类任务需上级领导审核后进行"
      } else if (row.BeforeTaskStatus == 5) {
        return "子任务申请取消需上级指派人审核"
      }
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatApprovalTime: function (row, column) {
      return (!row.ApprovalTime || row.ApprovalTime == '' || row.ApprovalTime.indexOf('0001')!=-1) ? '' : util.formatDate.dateformat(new Date(row.ApprovalTime));
    },
    formatTipApprovalTime: function (row, column) {
      return (!row.ApprovalTime || row.ApprovalTime == '' || row.ApprovalTime.indexOf('0001')!=-1) ? '' : util.formatDate.format(new Date(row.ApprovalTime), 'yyyy-MM-dd hh:mm');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getPageProcessedTasks();
    },
    getPageProcessedTasks () {
      var user = JSON.parse(window.localStorage.user);
      let para = {
        userCode: user.sub,
        pageIndex: this.page,
        pageSize: this.pageSize,
        queryKey: this.filters.name
      };
      QueryPageProcessedTasksByUserCode20210130(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        var arr=new Array();
        var number = (this.page-1)*this.pageSize;
        res.data.response.data.forEach(element => {
          // console.log(element);
          element.Number=++number;
          // element.MarginLeft=3;
          element.Level = 1;
          // this.mapLevel.set(element.Id,1);
          arr.push(element)
        });
        this.users = arr;
      });
    },
    //双击查看详细信息
    checkInfo (row) {
      if (row.NoTask > 0) {
        return;
      }
      this.checkVisible = true;
      this.checkForm = row;
      return;
      if (row.TaskClassify == 1) {
        this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        if (row.PersonOfDuty != row.CreatedBy) {
          this.showAssign = false;
        } else {
          this.showAssign = true;
        }
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = false;
          } else {
            this.showAssign = true;
          }
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = false;
          } else {
            this.showAssign = true;
          }
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = false;
          } else {
            this.showAssign = true;
          }
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = false;
          } else {
            this.showAssign = true;
          }
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = false;
          } else {
            this.showAssign = true;
          }
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.Id, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      this.pValue1 = row.TaskProgressValue;
      GetClassifyList().then((res) => {
        this.CommonTaskClassifyList = res.data.response;
      });
      // this.checkForm = Object.assign({},row);
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        // this.tasks = res.data.response;
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            var arr = element.FileName.split(",");
            var arr2 = element.FileAddress.split(",");
            var files = [];
            for (var d = 0; d < arr.length; d++) {
              var f = {};
              f.FileName = arr[d];
              f.FileAddress = arr2[d];
              files.push(f);
            }
            element.Files = files;
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("processdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
      //this.getPageProcessedTasks();
    },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
  },
  mounted () {
    this.getPageProcessedTasks();
    var user = JSON.parse(window.localStorage.user);
    this.approvalpeo = user.name;
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList ? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  }
}
</script>
<style scoped>
.complatediv,
.complatecard {
  height: calc(100vh - 220px);
}
.processdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>